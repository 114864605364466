// Libraries
import { createStore, compose, combineReducers } from 'redux'

// Reducers
import languageReducer from './reducers/languageReducer'
import blogReducer from './reducers/blogReducer'

// Types
import { Language } from '../types'
import { BlogArticle, BlogHotArticle, Banner } from '../../api/models'

interface StoreCommon {
  language: Language
  currentLocation: string
}

interface StoreBlog {
  blogPosts: BlogArticle[]
  hotPosts: BlogHotArticle[]
  ads: Banner[]
}
export interface Store {
  common: StoreCommon
  blog: StoreBlog
}

export const initialCommonState: StoreCommon = {
  language: process.env.GATSBY_SUBSITE as Language,
  currentLocation: typeof window !== 'undefined' && (window as any).location.pathname
}

export const initialBlogState: StoreBlog = {
  blogPosts: [],
  hotPosts: [],
  ads: []
}

export const initialState: Store = {
  common: initialCommonState,
  blog: initialBlogState
}

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const combinedReducers = combineReducers({ common: languageReducer, blog: blogReducer })

const store = createStore(combinedReducers, composeEnhancers())

export default store
