// Default state
import { initialBlogState } from '../ReduxStore'

// Action types
import { BlogActions } from '../actions/blogActions'

// Types
import { BlogArticle } from '../../../api/models'

const languageReducer = (
  state = initialBlogState,
  action: { type: BlogActions; payload: BlogArticle[] }
) => {
  switch (action.type) {
    case BlogActions.SET_BLOG_POSTS:
      return { ...state, blogPosts: action.payload }
    case BlogActions.SET_HOT_POSTS:
      return { ...state, hotPosts: action.payload }
    case BlogActions.SET_ADS:
      return { ...state, ads: action.payload }
    default:
      return state
  }
}

export default languageReducer
