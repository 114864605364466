// Libraries
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
// Components
import CookiesSettingsAccordion, {
  CookiesSettingsAccordionProps
} from '../cookies-settings-accordion/CookiesSettingsAccordion'

interface CookiesSettingsProps {
  cookieOptions: CookiesSettingsAccordionProps[]
}

const CookiesSettings = (props: CookiesSettingsProps) => {
  const { cookieOptions } = props
  const { t } = useTranslation('cookiesBar')

  return (
    <Settings>
      {cookieOptions.map(item => (
        <CookiesSettingsAccordion key={item.title} {...item} />
      ))}
    </Settings>
  )
}

export default CookiesSettings

const Settings = styled.div`
  font-size: 14px;
  margin-top: 1em;
`

const Text = styled.p`
  font-weight: 300;
  padding-bottom: 40px;
  border-bottom: 1px solid #e3e3e3;
`
