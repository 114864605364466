import { css } from 'styled-components'

export const contentStyles = css`
  width: 100%;
  max-width: var(--content-width);
  margin: 0 auto;
`

export const headerStyles = css`
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2em;

  span {
    display: block;
  }

  @media screen and (min-width: 769px) {
    font-size: 40px;
  }
`
