// Types
import { Language } from '../../types'

// Language Actions Types
export enum LanguageActions {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
}

// Changing setted language
export const changeLanguage = (language: Language) => ({
  type: LanguageActions.CHANGE_LANGUAGE,
  payload: language
})

export enum LocationActions {
  CURRENT_LOCATION = 'CURRENT_LOCATION'
}

export const changeCurrentLocation = (location: string) => ({
  type: LocationActions.CURRENT_LOCATION,
  payload: location
})
