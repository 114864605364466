import styled, { css } from 'styled-components'

const OutlinedTitle = styled.h2<{ $big?: boolean }>`
  display: flex;
  gap: 12px;
  color: transparent;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 4px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--text-color);

  ${({ $big }) =>
    $big &&
    css`
      @media (min-width: 769px) {
        font-size: 48px;
        line-height: 58px;
      }
    `}

  > em {
    font-style: normal;
    -webkit-text-stroke-color: var(--light-blue);
  }
`

export default OutlinedTitle
