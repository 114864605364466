// Libraries
import { useMemo, useState } from 'react'
import { styled } from 'styled-components'

export interface CookiesSettingsAccordionProps {
  id: string
  title: string
  description: string
  isChecked: boolean
  toggleHandler?: () => void
}

const CookiesSettingsAccordion = (props: CookiesSettingsAccordionProps) => {
  const { id, title, description, isChecked, toggleHandler } = props

  const [isExpanded, setIsExpanded] = useState(false)
  const isDisabled = useMemo(() => typeof toggleHandler === 'undefined', [toggleHandler])

  const toggleExpandHandler = () => setIsExpanded(!isExpanded)

  return (
    <Accordion>
      <Title onClick={toggleExpandHandler}>{title}</Title>
      <Input $isChecked={isChecked} $isDisabled={isDisabled}>
        <input
          type="checkbox"
          name={id}
          id={id}
          checked={isChecked}
          onChange={toggleHandler}
          disabled={isDisabled}
        />
      </Input>
      <Expand $isExpanded={isExpanded} onClick={toggleExpandHandler}></Expand>
      {isExpanded && <p>{description}</p>}
    </Accordion>
  )
}

export default CookiesSettingsAccordion

const Accordion = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 32px 15px;
  gap: 0 15px;
  font-size: 14px;
  padding: 20px 0;
  border-bottom: 1px solid #e3e3e3;

  > * {
    grid-column: span 1;
  }
`

const Title = styled.h5`
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
`

const Input = styled.label<{ $isChecked: boolean; $isDisabled: boolean }>`
  position: relative;
  display: block;
  width: 32px;
  height: 20px;
  border-radius: 20px;
  background-color: ${({ $isChecked }) => ($isChecked ? '#78bf21' : 'rgba(1, 1, 3, 0.5)')};
  transition: background-color 0.24s;
  cursor: pointer;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.65 : 1)};

  input {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 50%;
    transform: translateY(-50%) translateX(${({ $isChecked }) => ($isChecked ? '14px' : '2px')});
    transition: transform 0.24s;
  }
`

const Expand = styled.button<{ $isExpanded: boolean }>`
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border: none;

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-left: 1.5px solid #fff;
    border-top: 1.5px solid #fff;
    transform: rotate(${({ $isExpanded }) => ($isExpanded ? '45deg' : '225deg')});
    transition: transform 0.24s;
  }
`
