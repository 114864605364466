// Libraries
import { Provider } from 'react-redux'

// Redux store
import store from './ReduxStore'

const ReduxWrapper = ({ children }: { children: React.ReactNode }) => {
  return <Provider store={store}>{children}</Provider>
}

export default ReduxWrapper
