import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { contentStyles } from '../../styles'
import email from '../../images/icons/email.svg'
import instagram from '../../images/icons/instagram.svg'
import phone from '../../images/icons/phone.svg'
import pin from '../../images/icons/pin-blue.svg'
import { StaticImage } from 'gatsby-plugin-image'
import { useLanguage } from '../../hooks/useLanguage'

const listScrollSpeed = 500

export default function Footer() {
  const wrapperRef = useRef<HTMLElement>(null)
  const listCollapserRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [height, setHeight] = useState(0)
  const { isEN } = useLanguage()

  const handleListButtonClick = () => {
    const listHeight = listRef.current?.clientHeight || 0

    setHeight(height === 0 ? listHeight : 0)

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight)
    }, listScrollSpeed)
  }

  const handleResize = () => {
    const listCollapserHeight = listCollapserRef.current?.clientHeight || 0
    const listHeight = listRef.current?.clientHeight || 0

    if (listCollapserHeight === 0 || listHeight == listCollapserHeight) return

    setHeight(listHeight)
  }

  useEffect(() => {
    if (typeof window === 'undefined') return

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Content>
        <Column data-content="company">
          <Title>Onex Group</Title>

          <Address>
            <ul>
              <li className="link">
                <a
                  className="desktop"
                  href="https://g.page/onex-group?share"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={pin} alt="pinezka" />
                  <span>
                    al. Niepodległości 18
                    <br />
                    02-653 Warszawa
                  </span>
                </a>
                <a
                  className="mobile"
                  href="https://maps.google.com/maps/place/Onex+Group/@52.184189,21.0205907,17z/data=!3m1!4b1!4m5!3m4!1s0x471ecd39f01e4999:0xf49bd2da8e2641c!8m2!3d52.184189!4d21.0205907"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={pin} alt="pinezka" />
                  <span>
                    al. Niepodległości 18
                    <br />
                    02-653 Warszawa
                  </span>
                </a>
              </li>
              <li>
                NIP: 5751726496
                <br />
                REGON: 240028596
              </li>
            </ul>
          </Address>
        </Column>

        <Column data-content="contact">
          <Title>{isEN ? 'Contact' : 'Kontakt'}</Title>

          <ul>
            <li>
              <a href="tel:343339777">
                <img src={phone} alt="telefon" />
                34 333 97 77
              </a>
            </li>

            <li>
              <a href="mailto:info@onexgroup.pl">
                <img src={email} alt="email" />
                info@onexgroup.pl
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/onex.group/" target="_blank" rel="noreferrer">
                <img src={instagram} alt="instagram" />
                onex.group
              </a>
            </li>
          </ul>
        </Column>

        <Column data-content="services">
          <Title>{isEN ? 'Our services' : 'Nasze serwisy'}</Title>

          <ul>
            <li>
              <a href="https://www.onexgroup.pl/" target="_blank" rel="noreferrer">
                onex.group
              </a>
            </li>
            <li>
              <a href="https://www.onexstore.pl/" target="_blank" rel="noreferrer">
                onex.store
              </a>
            </li>
            <li>
              <a href="https://www.centrumxp.pl/" target="_blank" rel="noreferrer">
                CentrumXP
              </a>
            </li>

            <li>
              <a href="https://akademia.centrumxp.pl/" target="_blank" rel="noreferrer">
                Akademia CentrumXP
              </a>
            </li>
          </ul>
        </Column>

        <Column data-content="awards">
          <Title>{isEN ? 'Microsoft technology experts' : 'Eksperci technologii Microsoft'}</Title>

          <p>
            {isEN
              ? "We are the largest provider of Microsoft cloud services (M365, Azure) We're based in Poland for consumers, small and medium-sized enterprises, as well as a leader on the market of online training in Microsoft Technologies."
              : 'Jesteśmy największym w Polsce dostawcą usług chmurowych Microsoft (M365, Azure) dla konsumentów oraz małych i średnich przedsiębiorstw a także liderem na rynku szkoleń online z oprogramowania Microsoft.'}
          </p>

          <MicrosoftPartner>
            {isEN ? (
              <StaticImage
                className="footer_img"
                src="../../images/mc-partner-en.svg"
                alt="7 krotny partner microsoft"
                quality={100}
                placeholder="blurred"
              />
            ) : (
              <StaticImage
                className="footer_img"
                src="../../images/7-stars-partner.svg"
                alt="7 krotny partner microsoft"
                quality={100}
                placeholder="blurred"
              />
            )}
          </MicrosoftPartner>

          <ListCollapser ref={listCollapserRef} style={{ height }}>
            <ul ref={listRef}>
              <li>
                {isEN ? 'Microsoft Partner of the Year' : 'Partner Roku Microsoft'} 2018 – Reseller
              </li>
              <li>
                {isEN ? 'Microsoft Partner of the Year' : 'Partner Roku Microsoft'} 2017 - OEM
              </li>
              <li>{isEN ? 'Microsoft Partner of the Year' : 'Partner Roku Microsoft'} 2016</li>
              <li>
                {isEN ? 'Microsoft Partner of the Year' : 'Partner Roku Microsoft'} 2015 – MŚP
              </li>
              <li>Gold Application Development</li>
              <li>Gold Application Integration</li>
              <li>Gold Cloud Platform</li>
              <li>Gold Cloud Productivity</li>
              <li>Gold Data Analytics</li>
              <li>Gold Data Platform</li>
              <li>Gold Small and Midmarket Cloud Solutions</li>
              <li>Gold Windows and Devices</li>
              <li>Silver Collaboration and Content</li>
              <li>Silver Datacenter</li>
              <li>Silver Enterprise Mobility Management</li>
              <li>Silver Security</li>
            </ul>
          </ListCollapser>

          <ListButton onClick={handleListButtonClick}>
            {!height ? (isEN ? 'Show' : 'Zobacz') : isEN ? 'Hide' : 'Ukryj'}{' '}
            {isEN ? 'all awards' : 'wszystkie wyróżnienia'}
          </ListButton>
        </Column>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${contentStyles}
  padding: 0  0 32px;

  @media (min-width: 769px) {
    flex-direction: row;
    padding: 0 0 64px;
    overflow: hidden;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }
`

const Column = styled.div`
  @media (min-width: 769px) {
    flex: 1;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &[data-content='company'] {
    li {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      &:not(:first-child) {
        padding-left: 36px;
        margin-top: 24px;

        @media (min-width: 769px) {
          margin-top: 40px;
        }
      }

      img {
        margin-top: 4px;
      }
    }

    .link {
      display: block;

      .mobile {
        display: none;
      }

      a {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        & > * {
          display: block;
        }
      }

      @media screen and (hover: none) {
        .desktop {
          display: none;
        }

        .mobile {
          display: flex;
        }
      }
    }
  }

  &[data-content='contact'] {
    li {
      margin-bottom: 8px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  &[data-content='services'] {
    a {
      opacity: 0.5;
      transition: opacity 0.1s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &[data-content='awards'] {
    img {
      margin: 23px 0 17px;
    }
  }
`

const Title = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  margin-bottom: 29px;
`

const Address = styled.address`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  font-style: normal;
`

const ListCollapser = styled.div`
  transition: height ${listScrollSpeed}ms;
  overflow: hidden;
`

const ListButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  color: #4a90e2;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0;
  cursor: pointer;
  text-align: left;
`

const MicrosoftPartner = styled.div`
  position: relative;
  width: 70%;
  max-width: 247px;

  .footer_img,
  img {
    width: 100% !important;
    height: auto !important;
  }
`
