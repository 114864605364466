exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-study-index-tsx": () => import("./../../../src/pages/case-study/index.tsx" /* webpackChunkName: "component---src-pages-case-study-index-tsx" */),
  "component---src-pages-case-study-mokate-tsx": () => import("./../../../src/pages/case-study/mokate.tsx" /* webpackChunkName: "component---src-pages-case-study-mokate-tsx" */),
  "component---src-pages-chmura-i-cyberbezpieczenstwo-tsx": () => import("./../../../src/pages/chmura-i-cyberbezpieczenstwo.tsx" /* webpackChunkName: "component---src-pages-chmura-i-cyberbezpieczenstwo-tsx" */),
  "component---src-pages-dane-i-analityka-tsx": () => import("./../../../src/pages/dane-i-analityka.tsx" /* webpackChunkName: "component---src-pages-dane-i-analityka-tsx" */),
  "component---src-pages-en-blog-case-study-index-tsx": () => import("./../../../src/pages/en/blog/case-study/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-case-study-index-tsx" */),
  "component---src-pages-en-blog-case-study-tsx": () => import("./../../../src/pages/en/blog/case-study/[...].tsx" /* webpackChunkName: "component---src-pages-en-blog-case-study-tsx" */),
  "component---src-pages-en-blog-cloud-and-security-index-tsx": () => import("./../../../src/pages/en/blog/cloud-and-security/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-cloud-and-security-index-tsx" */),
  "component---src-pages-en-blog-cloud-and-security-tsx": () => import("./../../../src/pages/en/blog/cloud-and-security/[...].tsx" /* webpackChunkName: "component---src-pages-en-blog-cloud-and-security-tsx" */),
  "component---src-pages-en-blog-data-and-analytics-index-tsx": () => import("./../../../src/pages/en/blog/data-and-analytics/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-data-and-analytics-index-tsx" */),
  "component---src-pages-en-blog-data-and-analytics-tsx": () => import("./../../../src/pages/en/blog/data-and-analytics/[...].tsx" /* webpackChunkName: "component---src-pages-en-blog-data-and-analytics-tsx" */),
  "component---src-pages-en-blog-index-tsx": () => import("./../../../src/pages/en/blog/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-index-tsx" */),
  "component---src-pages-en-blog-microsoft-365-and-modern-workplace-index-tsx": () => import("./../../../src/pages/en/blog/microsoft-365-and-modern-workplace/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-microsoft-365-and-modern-workplace-index-tsx" */),
  "component---src-pages-en-blog-microsoft-365-and-modern-workplace-tsx": () => import("./../../../src/pages/en/blog/microsoft-365-and-modern-workplace/[...].tsx" /* webpackChunkName: "component---src-pages-en-blog-microsoft-365-and-modern-workplace-tsx" */),
  "component---src-pages-en-blog-software-development-index-tsx": () => import("./../../../src/pages/en/blog/software-development/index.tsx" /* webpackChunkName: "component---src-pages-en-blog-software-development-index-tsx" */),
  "component---src-pages-en-blog-software-development-tsx": () => import("./../../../src/pages/en/blog/software-development/[...].tsx" /* webpackChunkName: "component---src-pages-en-blog-software-development-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kariera-tsx": () => import("./../../../src/pages/kariera.tsx" /* webpackChunkName: "component---src-pages-kariera-tsx" */),
  "component---src-pages-m-365-modern-workplace-tsx": () => import("./../../../src/pages/m365-modern-workplace.tsx" /* webpackChunkName: "component---src-pages-m-365-modern-workplace-tsx" */),
  "component---src-pages-podziekowanie-tsx": () => import("./../../../src/pages/podziekowanie.tsx" /* webpackChunkName: "component---src-pages-podziekowanie-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-poznajnas-tsx": () => import("./../../../src/pages/poznajnas.tsx" /* webpackChunkName: "component---src-pages-poznajnas-tsx" */),
  "component---src-pages-software-development-tsx": () => import("./../../../src/pages/software-development.tsx" /* webpackChunkName: "component---src-pages-software-development-tsx" */),
  "component---src-pages-twojprojekt-tsx": () => import("./../../../src/pages/twojprojekt.tsx" /* webpackChunkName: "component---src-pages-twojprojekt-tsx" */)
}

