// Libraries
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
// Compoents
import Button from '../Button'
import CookiesSettings from './components/cookies-settings/CookiesSettings'
// Icons
import Icon from '../../images/cookies.inline.svg'
import IconClose from '../../images/close.inline.svg'
// Types
import { CookiesSettingsAccordionProps } from './components/cookies-settings-accordion/CookiesSettingsAccordion'
import { Link } from 'gatsby'
// Hooks and helpers
import { useLanguage } from '../../hooks/useLanguage'
import { links } from '../../links'

const INITIAL_SETTINGS = {
  technical: true,
  analytics: false,
  marketing: false
}

interface CookiesProps {
  closeCookieHandler: () => void
}

const Cookies = (props: CookiesProps) => {
  const { closeCookieHandler } = props
  const {currentLanguage} = useLanguage()

  const [showSettings, setShowSettings] = useState(false)
  const [cookieSettings, setCookieSettings] = useState(INITIAL_SETTINGS)

  const { t } = useTranslation()

  const toggleSettingsHandler = () => setShowSettings(!showSettings)
  const toggleCookie = (option: 'analytics' | 'marketing') =>
    setCookieSettings({ ...cookieSettings, [option]: !cookieSettings[option] })

  const cookieOptions: CookiesSettingsAccordionProps[] = [
    {
      id: 'technical',
      isChecked: cookieSettings.technical,
      title: t('cookiesBar.technicalTitle'),
      description: t('cookiesBar.technicalDescription')
    },
    {
      id: 'analytics',
      isChecked: cookieSettings.analytics,
      title: t('cookiesBar.analyticalTitle'),
      description: t('cookiesBar.analyticalDescription'),
      toggleHandler: () => toggleCookie('analytics')
    },
    {
      id: 'marketing',
      isChecked: cookieSettings.marketing,
      title: t('cookiesBar.marketingTitle'),
      description: t('cookiesBar.marketingDescription'),
      toggleHandler: () => toggleCookie('marketing')
    }
  ]

  const acceptAllHandler = () => {
    localStorage.setItem('technical', 'true')
    localStorage.setItem('marketing', 'true')
    localStorage.setItem('analytics', 'true')
    return closeCookieHandler()
  }

  const acceptChoosedHandler = () => {
    localStorage.setItem('technical', cookieSettings.technical.toString())
    localStorage.setItem('marketing', cookieSettings.marketing.toString())
    localStorage.setItem('analytics', cookieSettings.analytics.toString())
    return closeCookieHandler()
  }

  return (
    <Wrapper>
      <Cookie>
        <Icon />
        <Close onClick={acceptAllHandler}>
          <IconClose />
        </Close>
        <Title>{t('cookiesBar.domainUsesCookies')}</Title>
        <Description>{t('cookiesBar.description')} <Link to={links(currentLanguage).policy}>{t('cookiesBar.link')}</Link></Description>
        {showSettings && <CookiesSettings cookieOptions={cookieOptions} />}
        <Options>
          <Button
            $secondary={true}
            $bordercolor="#6633CC"
            onClick={!showSettings ? toggleSettingsHandler : acceptChoosedHandler}
          >
            {!showSettings ? t('cookiesBar.settings') : t('cookiesBar.save')}
          </Button>
          <Button onClick={acceptAllHandler}>{t('cookiesBar.acceptAll')}</Button>
        </Options>
      </Cookie>
    </Wrapper>
  )
}

export default Cookies

const Wrapper = styled.div`
  position: fixed;
  z-index: 99999;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 51, 0.5);
`

const Cookie = styled.div`
  position: relative;
  padding: 32px 24px;
  max-height: 95vh;
  max-width: 680px;
  width: 100%;
  margin: 0 15px;
  overflow-x: auto;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 15px;
  background: radial-gradient(
    348.76% 100.89% at 21.19% 18.02%,
    rgba(137, 158, 221, 0.26) 0%,
    rgba(141, 113, 189, 0.26) 100%
  );
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(13px);

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  .cookie__icon {
    width: 47px;
    height: 47px;
  }

  @media screen and (min-width: 768px) {
    padding: 48px 40px 48px 48px;

    .cookie__icon {
      width: 67px;
      height: 67px;
    }
  }
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  background-color: transparent;
  outline: transparent;
  border: none;

  cursor: pointer;

  svg {
    width: 14px;
    height: auto;
    path {
      stroke: #fff;
    }
  }
`

const Title = styled.h4`
  max-width: 550px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
`

const Description = styled.p`
  font-size: 14px;
  font-weight: 300;
  a {
    color: #00CDE8;
    font-weight: 600;
    text-decoration: underline;
  }
`

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 50px;

  > * {
    flex: 1 0 180px;
  }

  @media screen and (min-width: 768px) {
    > * {
      flex: auto;
      max-width: 196px;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
`
