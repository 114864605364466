// Libraries
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

// Types
import { Language } from '../types'
import { Store } from '../redux/ReduxStore'

export const useLanguage = (): {isPL: boolean, isEN: boolean, currentLanguage: Language} =>{
  const currentLanguage = useSelector((state: Store) => state.common.language)

  const languages = useMemo(
    () => ({ isPL: currentLanguage === Language.PL, isEN: currentLanguage === Language.EN, currentLanguage: currentLanguage}),
    [currentLanguage]
  )

  return languages
}
 