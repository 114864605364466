import { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import OutlinedTitle from './typographies/OutlinedTitle'
import { links } from '../links'

import { useLanguage } from '../hooks/useLanguage'

export default function Menu({ isOpened, onClose }: { isOpened: boolean; onClose: () => void }) {
  useEffect(() => {
    document.body.style.overflow = isOpened ? 'hidden' : 'auto'
  }, [isOpened])
  const { isPL, currentLanguage } = useLanguage()

  return (
    <Wrapper $isOpened={isOpened}>
      <CloseButton onClick={onClose} aria-label="Zamknij menu" />

      <Link onClick={onClose} to={links(currentLanguage).menu.ourServices}>
        {isPL ? 'Co robimy?' : 'What we do?'}
      </Link>
      <Link onClick={onClose} to={links(currentLanguage).menu.knowUs}>
        {isPL ? 'Poznaj nas' : 'Discover Onex'}
      </Link>
      <Link onClick={onClose} to={links(currentLanguage).menu.career}>
        {isPL ? 'Kariera' : 'Career'}
      </Link>
      {isPL ? (
        <a href={links(currentLanguage).menu.blog} target="_blank" rel="noreferrer">
          Blog
        </a>
      ) : (
        <Link to={links(currentLanguage).menu.blog}>Blog</Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isOpened: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 34px;

  width: 100%;
  height: 100%;
  padding: 34px 20px 20px;

  position: fixed;
  top: 0;
  left: ${({ $isOpened }) => ($isOpened ? 0 : '-100%')};
  opacity: ${({ $isOpened }) => ($isOpened ? 1 : 0)};
  ${({ $isOpened }) => !$isOpened && 'pointer-events: none;'};
  z-index: 10;

  background-color: var(--background-color);
  transition-property: left, opacity;
  transition-duration: 0.3s, 0s;
  transition-delay: 0s, ${({ $isOpened }) => ($isOpened ? 0 : '0.3s')};

  ${OutlinedTitle}:hover {
    -webkit-text-stroke-color: var(--light-blue);
  }

  a {
    font-weight: 500;
    line-height: 19px;
    color: #fff;
  }
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  width: 20px;
  padding: 20px;

  position: absolute;
  top: 10px;
  right: 10px;

  background: none;
  border: none;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    width: 50%;
    height: 2px;
    position: absolute;
    background: var(--text-color);
    transition: transform 0.2s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before {
      transform: rotate(135deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
`
