import type { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { HEADER_BREAKPOINT } from '../constants'

export default function MobileMenuButton(props: HTMLAttributes<HTMLButtonElement>) {
  return (
    <Button {...props}>
      <span />
      <span />
      <span />
    </Button>
  )
}

const Button = styled.button`
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: inherit;

  width: 22px;
  height: 14px;
  padding: 16px 10px;
  margin-right: 8px;
  box-sizing: content-box;

  background: none;
  border: none;
  cursor: pointer;

  @media (min-width: ${HEADER_BREAKPOINT}) {
    display: none;
  }

  span {
    height: 2px;
    background: currentColor;
    border-radius: 4px;
    transition: width 0.2s;

    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2) {
      width: 66%;
    }

    &:nth-child(3) {
      width: 50%;
    }
  }

  &:hover span {
    width: 100%;
  }
`
