import React, { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import styled from 'styled-components'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import Header from './src/components/layout-elements/Header'
import Footer from './src/components/layout-elements/Footer'
import CustomParticles from './src/components/Particles'
import ReduxWrapper from './src/redux/ReduxWrapper'
import './src/css/styles.css'
import Cookies from './src/components/cookies/Cookies'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require('./src/locales/en/en.json')
    },
    pl: {
      translation: require('./src/locales/pl/pl.json')
    }
  },
  lng: process.env.GATSBY_SUBSITE,
  fallbackLng: process.env.GATSBY_SUBSITE,
  interpolation: {
    escapeValue: true
  }
})

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(MotionPathPlugin)

const App = ({ children }) => {
  const [isCookiesVisible, setIsCookiesVisible] = useState(false)

  useEffect(() => {
    const isCookiesAccepted =
      !!localStorage.getItem('technical') &&
      !!localStorage.getItem('marketing') &&
      !!localStorage.getItem('analytics')

    if (!isCookiesAccepted) {
      setTimeout(() => {
        setIsCookiesVisible(true)
      }, 5000)
    }
  }, [])

  return (
    <I18nextProvider i18n={i18n}>
      <ReduxWrapper>
        <Wrapper>
          <Header />
          {isCookiesVisible && <Cookies closeCookieHandler={() => setIsCookiesVisible(false)} />}
          {children}
          <Footer />
          <CustomParticles />
        </Wrapper>
      </ReduxWrapper>
    </I18nextProvider>
  )
}
export const wrapPageElement = ({ element }) => <App>{element}</App>

const Wrapper = styled.div`
  width: 100%;
`
