import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Particles from 'react-particles'
import { tsParticles, SingleOrMultiple, RecursivePartial, IOptions } from 'tsparticles-engine'
import { loadSlim } from 'tsparticles-slim'
const config: RecursivePartial<IOptions> = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800
      }
    },
    color: {
      value: '#00cde8'
    },
    opacity: {
      value: 0.1,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false
      }
    },
    size: {
      value: 5,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false
      }
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: '#00cde8',
      opacity: 0.1,
      width: 1
    },
    move: {
      enable: true,
      speed: 0.25,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200
      }
    }
  },
  retina_detect: true
}

export default function CustomParticles() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <>
      {isClient && (
        <Wrapper id="particles" init={particlesInit} options={config} />
      )}
    </>
  );
}

const Wrapper = styled(Particles)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
`;