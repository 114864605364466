// Default state
import { initialCommonState } from '../ReduxStore'

// Action types
import { LanguageActions, LocationActions } from '../actions/commonActions'

const languageReducer = (
  state = initialCommonState,
  action: { type: LanguageActions | LocationActions; payload: string }
) => {
  switch (action.type) {
    case LanguageActions.CHANGE_LANGUAGE:
      return { ...state, language: action.payload }
    case LocationActions.CURRENT_LOCATION:
      return { ...state, currentLocation: action.payload }
    default:
      return state
  }
}

export default languageReducer
