// Types
import { Language } from './types'

export const links = (language: Language) => {
  const isPL = language === Language.PL

  return process.env.GATSBY_NODE === 'development'
    ? {
        menu: {
          home: isPL ? '/' : '/en/',
          ourServices: isPL ? '/#nasze-uslugi' : '/en/#our-services',
          knowUs: isPL ? '/poznajnas' : '/en/poznajnas',
          caseStudy: isPL ? '/case-study' : '/en/case-study',
          career: isPL ? '/kariera' : '/en/kariera',
          yourProject: isPL ? '/twojprojekt' : '/en/twojprojekt',
          blog: isPL ? 'https://www.centrumxp.pl/' : '/en/blog/'
        },
        services: {
          data: isPL ? '/dane-i-analityka/' : '/en/dane-i-analityka',
          m365: isPL ? '/m365-modern-workplace/' : '/en/m365-modern-workplace',
          cloud: isPL ? '/chmura-i-cyberbezpieczenstwo/' : '/en/chmura-i-cyberbezpieczenstwo',
          software: isPL ? '/software-development/' : '/en/software-development'
        },
        blogCategories: {
          data: isPL ? 'https://www.centrumxp.pl/' : '/en/blog/data-and-analytics/',
          m365: isPL ? 'https://www.centrumxp.pl/' : '/en/blog/microsoft-365-and-modern-workplace/',
          cloud: isPL ? 'https://www.centrumxp.pl/' : '/en/blog/cloud-and-security/',
          software: isPL ? 'https://www.centrumxp.pl/' : '/en/blog/software-development/',
          caseStudy: isPL ? 'https://www.centrumxp.pl/' : '/en/blog/case-study/'
        },
        successPage: isPL ? '/podziekowanie' : '/en/podziekowanie',
        error: isPL ? '/404/' : '/en/404/',
        policy: isPL ? '/polityka-prywatnosci/' : '/en/polityka-prywatnosci/'
      }
    : {
        menu: {
          home: isPL
            ? process.env.GATSBY_NODE === 'test'
              ? 'http://test.onexgroup.pl/'
              : 'https://www.onexgroup.pl/'
            : process.env.GATSBY_NODE === 'test'
            ? 'http://test.onex-group.com/'
            : 'https://www.onex-group.com/',
          ourServices: isPL ? '/#nasze-uslugi' : '/#our-services/',
          knowUs: isPL ? '/poznajnas/' : '/discover-us/',
          caseStudy: isPL ? '/case-study' : '/case-study',
          career: isPL ? '/kariera/' : '/career/',
          yourProject: isPL ? '/twojprojekt/' : '/your-project/',
          blog: isPL ? 'https://www.centrumxp.pl/' : '/blog/'
        },
        services: {
          data: isPL ? '/dane-i-analityka/' : '/data-and-analytics/',
          m365: isPL ? '/m365-modern-workplace/' : '/m365-modern-workplace/',
          cloud: isPL ? '/chmura-i-cyberbezpieczenstwo/' : '/cloud-and-cybersecurity/',
          software: isPL ? '/software-development/' : '/software-development/'
        },
        blogCategories: {
          data: isPL ? 'https://www.centrumxp.pl/' : '/blog/data-and-analytics/',
          m365: isPL ? 'https://www.centrumxp.pl/' : '/blog/microsoft-365-and-modern-workplace/',
          cloud: isPL ? 'https://www.centrumxp.pl/' : '/blog/cloud-and-security/',
          software: isPL ? 'https://www.centrumxp.pl/' : '/blog/software-development',
          caseStudy: isPL ? 'https://www.centrumxp.pl/' : '/blog/case-study/'
        },
        successPage: isPL ? '/podziekowanie/' : '/thank-you/',
        error: '/404/',
        policy: isPL ? '/polityka-prywatnosci/' : '/policy/'
      }
}
