import { BlogArticle } from '../../../api/models'

// Language Actions Types
export enum BlogActions {
  SET_BLOG_POSTS = 'SET_BLOG_POSTS',
  SET_HOT_POSTS = 'SET_HOT_POSTS',
  SET_ADS = 'SET_ADS'
}

// Changing setted language
export const changePosts = (posts: BlogArticle[]) => ({
  type: BlogActions.SET_BLOG_POSTS || BlogActions.SET_HOT_POSTS || BlogActions.SET_ADS,
  payload: posts
})