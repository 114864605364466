import type { ReactNode } from 'react'
import type { IGatsbyImageData } from 'gatsby-plugin-image'
import { BlogArticle } from '../api/models'

// Types
import { BlogMainCategory, Category } from '../api/models'
export interface Brand {
  id: string
  name: JSX.Element
  logoSrc: string
  symbolSrc?: string
  photoSrc?: string
  description?: ReactNode
  url: string
}
export interface CustomInputProps {
  inputProps: {
    id: string
    name: string
    type: 'text' | 'number' | 'phone' | 'email' | 'checkbox' | 'textarea'
    placeholder?: string
    requiredField?: boolean
    value?: string
    checked?: boolean
    isValid: null | boolean
    autoComplete?: string
    userFriendlyName?: string
    focused?: boolean
  }
  label: string
  successMessage?: string
  onChange: (key: string, value: string | boolean) => void
}

interface CommonContactFormStateProps {
  value: string | boolean
  userFriendlyName: string
  isValid: null | boolean
  focused: boolean
  requiredField: boolean
}
export interface ContactFormStateProps {
  email: CommonContactFormStateProps
  phone: CommonContactFormStateProps
  question: CommonContactFormStateProps
  policy: CommonContactFormStateProps
}

export enum ContactFormEndpoints {
  Services = 'uslugi@onexgroup.pl',
  Workplace = 'nowoczesnapraca@onexgroup.pl',
  Cloud = 'chmura@onexgroup.pl',
  Data = 'daneianalityka@onexgroup.pl',
  Software = 'rozwojoprogramowania@onexgroup.pl'
}

export interface ContactFormSectionProps {
  endpoint: ContactFormEndpoints
  en?: boolean
}
export interface OurServicesCard {
  id: number
  name: string
  content: string
  subsectionContent: string[]
  image: string
  url: string
}

export interface CompetenceItemProps {
  title: string
  color: 'gold' | 'silver'
}

export interface ListProsItemType {
  title: string
  text: string
}

export interface ListPropType {
  data: ListProsItemType[]
  directCol?: boolean
  svgColor: string
}

export interface ActivityItemProps {
  id: number
  marginLeft: string
  title: string
  description: string
  textMarginLeft: number
  icon: string
  iconPadding: number
}

export interface OurCultureAndRulesSectionProps {
  items?: ActivityItemProps[]
  stroke: string
  activityTitle: string
}
export interface CooperationScenariosProps {
  title: string
  imageBackground?: string
  image?: IGatsbyImageData
  name?: string
  role?: string
  content: {
    title: string
    text: string
  }[]
  nameRoleMarginTop: number
  nameRoleMarginLeft: number
  listMarginLeft: number
  photoWidth: number
  photoHeight: number
}

export enum PageType {
  Article = 'article',
  Website = 'website'
}
export interface SEOProps {
  title?: string
  description?: string
  siteType?: PageType
  image?: string
  pathname?: string
  children?: JSX.Element
}

export interface OurServicesHeaderProps {
  title: string
  subtitle: string
  description?: string
}

export interface MostCommonProjectsProps {
  title: string
  subtitle: string
  details: ListProsItemType[]
  buttonTextColor: 'white' | 'black'
  buttonUrl: string
}
export interface DescriptionProps {
  title: string
  description: string | JSX.Element
}

export enum Language {
  EN = 'en',
  PL = 'pl'
}

export interface Images {
  bluredLogoOG: IGatsbyImageData
  logoOS: IGatsbyImageData
  backgroundImage: IGatsbyImageData
  // Offices
  officeForeign: IGatsbyImageData
  officeCzestochowa: IGatsbyImageData
  officeHome: IGatsbyImageData
  officeSosnowiec: IGatsbyImageData
  officeWarszawa: IGatsbyImageData
  officeGliwice: IGatsbyImageData
  // People
  dawidDetko: IGatsbyImageData
  pawelCzarnecki: IGatsbyImageData
  adamJanson: IGatsbyImageData
  pawelCzarnecki2: IGatsbyImageData
  piotrGorski: IGatsbyImageData
  hryciuk: IGatsbyImageData
  hyjek: IGatsbyImageData
  kanurski: IGatsbyImageData
  malolepszy: IGatsbyImageData
  // Shadows
  consultingShadow: IGatsbyImageData
  cloudShadow: IGatsbyImageData
  m365Shadow: IGatsbyImageData
  softwareShadow: IGatsbyImageData
  // Case Study
  coffe: IGatsbyImageData
  mokateTechnologies: IGatsbyImageData
  mokateArchitecture: IGatsbyImageData
  mokateAnalytics: IGatsbyImageData
  caseStudyLaptop: IGatsbyImageData
}

export interface CategoryButtonProps {
  color: string
  text: string
  link: string
  isActive?: boolean
  dotted?: boolean
}

export enum EntryCardType {
  Plain = 'plain',
  Aside = 'aside',
  Extend = 'extend',
  Huge = 'huge'
}

export interface EntryCardProps {
  type: EntryCardType
  title: string
  link: string
  image: string
  description?: string
  date?: string
  postId?: number
  categories?: string[]
  mainCategory: BlogMainCategory | Category
  additionalCategories?: BlogMainCategory[]
  onMouseUpHandler?: (link: string) => Promise<void> | void
  onClick?: (id: number, currencyId: number) => void
}

export interface CategoryListProps {
  categoryId: number
  title: string
  link?: string
  wrapItems?: boolean
  postsAmount?: number
  categoryPosts?: BlogArticle[]
}

export interface EmployeeCardProps {
  id: string
  employee?: {
    name: string
    position: string
    image: any
    shadow: any
  }
  title: string
  logo: any
  details: string[]
  isCurrent: boolean
  isLeft: boolean
  isRight: boolean
  link: string
}

export interface ArticleProps {
  data: BlogArticle
}

export interface ArticleBannerProps {
  url: string
  title: string
}

export enum CategoryId {
  M365 = 31,
  SoftwareDevelopment = 32,
  DataAndAnalytics = 33,
  CloudAndSecurity = 34,
  CaseStudy = 35
}

export interface BlogCategoryProps {
  seoTitle: string
  seoDescription: string
  title: string
  subtitle: string
  description?: string
  logo: JSX.Element
  categoryTitle: string
  categoryId: number
}
