import { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { contentStyles } from '../../styles'
import Menu from '../Menu'
import MobileMenuButton from '../MobileMenuButton'
import logo from '../../images/logo.svg'
import Button from '../Button'
import email from '../../images/icons/email-outlined.svg'
import { useDispatch } from 'react-redux'
import { LanguageActions } from '../../redux/actions/commonActions'
import { Language } from '../../types'
import { links } from '../../links'
import { LocationActions } from '../../redux/actions/commonActions'
import { useLanguage } from '../../hooks/useLanguage'
import { changeLanguage, changeCurrentLocation } from '../../redux/actions/commonActions'
import { HEADER_BREAKPOINT, HEADER_BREAKPOINT_MAX } from '../../constants'

const SLICE_SLASHES = process.env.GATSBY_NODE === 'development' ? 0 : 1

export default function Header() {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [currentLocation, setCurrentLocation] = useState({
    pl: links(Language.PL).menu.home,
    en: links(Language.EN).menu.home
  })

  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const handleScroll = () => setHasScrolled(window.scrollY > 0)

    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const { isPL, currentLanguage } = useLanguage()
  const dispatch = useDispatch()

  const pathName = typeof window !== `undefined` && window.location.pathname

  const changeLanguageHandler = (language: Language) => {
    if (language === currentLanguage && currentLanguage !== undefined) return
    dispatch(changeLanguage(language))
  }

  useEffect(() => {
    if (typeof window === 'undefined') return

    dispatch(changeCurrentLocation(!!pathName ? pathName : '/'))
  }, [pathName])

  useEffect(() => {
    const addressPL = links(Language.PL)
    const addressEN = links(Language.EN)
    const addresses = [addressPL, addressEN]

    const domainPl = process.env.GATSBY_NODE === 'development' ? '' : addressPL.menu.home
    const domainEn = process.env.GATSBY_NODE === 'development' ? '' : addressEN.menu.home

    const getCurrentLocation = () => {
      const { pathname } = window.location

      addresses.forEach(address => {
        Object.keys(address).forEach(key => {
          const objKey = key as 'menu' | 'services' | 'successPage' | 'error' | 'policy'

          // Every Link without subkeys should be conditioned
          if ((objKey === 'successPage' || objKey === 'error') && pathname === address[objKey])
            return setCurrentLocation({
              // @ts-ignore
              pl: domainPl + addressPL.menu.home.slice(SLICE_SLASHES),
              // @ts-ignore
              en: domainEn + addressEN.menu.home.slice(SLICE_SLASHES)
            })

          Object.keys(address[objKey]).forEach(subKey => {
            if (objKey === 'successPage' || objKey === 'error' || objKey === 'policy') return
            return (
              // @ts-ignore
              address[objKey][subKey] === pathname &&
              setCurrentLocation({
                // @ts-ignore
                pl:
                  subKey === 'blog'
                    ? process.env.GATSBY_NODE === 'development'
                      ? '/'
                      : domainPl
                    : domainPl + addressPL[objKey][subKey].slice(SLICE_SLASHES),
                // @ts-ignore
                en: domainEn + addressEN[objKey][subKey].slice(SLICE_SLASHES)
              })
            )
          })
        })
      })
    }

    getCurrentLocation()
  }, [pathName])

  return (
    <Wrapper $hasScrolled={hasScrolled}>
      <Content ref={contentRef}>
        <MobileMenuButton onClick={() => setIsMenuOpened(true)} aria-label="Otwórz menu" />

        <Link to="/">
          <Logo src={logo} alt="onexgroup" />
        </Link>

        <DesktopLinks>
          <Link to={links(currentLanguage).menu.ourServices}>{isPL ? 'Usługi' : 'Services'}</Link>
          <Link to={links(currentLanguage).menu.knowUs}>
            {isPL ? 'Poznaj nas' : 'Discover Onex'}
          </Link>
          <Link to={links(currentLanguage).menu.caseStudy}>Case Studies</Link>
          <Link to={links(currentLanguage).menu.career}>{isPL ? 'Kariera' : 'Career'}</Link>
          {!isPL && (
            <a href={'https://licences.onex-group.com/'} target="_blank" rel="noreferrer">
              Licenses
            </a>
          )}
          {isPL ? (
            <a href={links(currentLanguage).menu.blog} target="_blank" rel="noreferrer">
              Blog
            </a>
          ) : (
            <Link to={links(currentLanguage).menu.blog}>Blog</Link>
          )}
          <Button as={Link} to={links(currentLanguage).menu.yourProject}>
            {isPL ? 'Twój projekt' : 'Your project'}
          </Button>
        </DesktopLinks>

        <LanguageSettings>
          {process.env.GATSBY_NODE !== 'development' ? (
            <>
              <a href={currentLocation.en}>EN {!isPL && <span>EN</span>}</a>
              <a href={currentLocation.pl}>PL {isPL && <span>PL</span>}</a>{' '}
            </>
          ) : (
            <>
              <Link to={currentLocation.en} onClick={() => changeLanguageHandler(Language.EN)}>
                EN {!isPL && <span>EN</span>}
              </Link>
              <Link to={currentLocation.pl} onClick={() => changeLanguageHandler(Language.PL)}>
                PL {isPL && <span>PL</span>}
              </Link>{' '}
            </>
          )}
        </LanguageSettings>

        <Link className="link--cta" to={links(currentLanguage).menu.yourProject}>
          <img src={email} alt="email" />
        </Link>
      </Content>

      <Menu isOpened={isMenuOpened} onClose={() => setIsMenuOpened(false)} />
    </Wrapper>
  )
}

const Wrapper = styled.nav<{ $hasScrolled: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  background: ${props => (props.$hasScrolled ? 'rgba(20, 20, 51, 0.8)' : 'transparent')};
  transition: background-color 0.2s;

  @media (min-width: ${HEADER_BREAKPOINT}) {
    .link--cta {
      display: none;
    }
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  ${contentStyles}
  height: 70px;
  padding: 0 16px;

  @media (min-width: ${HEADER_BREAKPOINT}) {
    height: 100px;
  }

  a {
    position: relative;
    color: inherit;
    text-decoration: none;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0px;
    opacity: 1;

    img {
      margin-bottom: -3px;
    }
  }
`

const Logo = styled.img`
  width: 125px;
  transition: width 0.2s;

  @media (min-width: 769px) {
    width: 160px;
  }
`

const DesktopLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  width: 100%;

  a {
    font-weight: 500;
  }

  @media (max-width: ${HEADER_BREAKPOINT_MAX}) {
    display: none;
  }

  @media screen and (min-width: 992px) {
    gap: 40px;
  }
`

const LanguageSettings = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 15px;
  gap: 10px;

  a {
    font-weight: 700;

    background-color: transparent;
    border: none;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: rgba(255, 255, 255, 1);
    }

    @media screen and (min-width: ${HEADER_BREAKPOINT}) {
      font-size: 14px;
    }
  }

  @media screen and (min-width: ${HEADER_BREAKPOINT}) {
    margin-left: 20px;
    margin-right: 0;
  }
`
