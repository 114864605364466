import styled from 'styled-components'

const Button = styled.button<{
  $secondary?: boolean
  $backgroundcolor?: string
  $textcolor?: string
  $bordercolor?: string
}>`
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50px;
  margin: 0%;

  background: ${({ $secondary, $backgroundcolor }) =>
    $backgroundcolor ?? ($secondary ? 'transparent' : '#6633CC')};
  border: ${({ $bordercolor }) => ($bordercolor ? '2px solid ' + $bordercolor : 'none')};
  border-radius: 7px;

  color: ${({ $textcolor }) => $textcolor ?? 'var(--text-color)'};
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-decoration: none;
  cursor: pointer;

  transition-property: color, $background-color;
  transition-duration: 0.34s;

  @media (min-width: 769px) {
    max-width: 240px;
  }

  &:hover {
    color: ${({ $bordercolor }) => ($bordercolor ? 'white' : '#6633CC')};
    background-color: ${({ $bordercolor }) => $bordercolor ?? 'white'};
  }
`

export default Button
